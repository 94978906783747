import { Environment, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import "./../css/iphone.css";

import * as THREE from "three";
import { IphoneFinal } from "../components/iphone/Iphone_final";
import { useEffect, useRef, useState } from "react";
import qr from "./../images/qr1.jpg";
import recurso8 from "./../images/recurso8.png";
import recurso9 from "./../images/recurso9.png";

const IphoneSection = ({ loadImage }) => {
  const [color, setColor] = useState("purple");
  const refDiv = useRef(null);
  const refColors = useRef(null);
  const refQr = useRef(null);
  const refDivCanvas = useRef(null);

  useEffect(() => {
    const functionResize = () => {
      const width = window.innerWidth;
      const img = document.querySelector("#recurso2");
      // const divCanvas = document.querySelector("#iphone-div-content-canvas");
      const divCanvas = refDiv.current;
      const divColors = refColors.current;
      const divQr = refQr.current;
      const top = divColors.getBoundingClientRect().top;

      // divQr.style.top = 50 + top + "px";

      if (width < 768) {
        // refDiv.current.style.height = "auto";
        divCanvas.style.width = "100%";
        // divCanvas.style.aspectRatio = 0.6;
        // divCanvas.style.aspectRatio = 1 / 2;
      } else {
        // console.log("height es", img.clientHeight);
        // refDiv.current.style.height = img.clientHeight + "px";
        // const widthDiv = img.clientWidth;
        // const heightDiv = img.clientHeight;
        // divCanvas.style.aspectRatio = widthDiv / heightDiv;
        divCanvas.style.width = "100%";
        // divCanvas.style.height = heightDiv + "px";
      }
      // divCanvas.style.aspectRatio = 1 / 1.5;
    };
    window.addEventListener("resize", functionResize);

    functionResize();
    return () => {
      window.removeEventListener("resize", functionResize);
    };
  }, []);
  return (
    // <div
    //   ref={refDiv}
    //   className="mt-4"
    //   style={{ border: "2px solid green", width: "100%" }}
    // >
    <div
      id="iphone-div-content-canvas"
      onMouseEnter={() => {
        document.body.style.cursor = "grab";
      }}
      onMouseLeave={() => {
        document.body.style.cursor = "default";
      }}
      ref={refDiv}

      // onTouchStart={(e) => {
      //   e.preventDefault();
      //   console.log("pointer", e.touches.length);
      // }}
      // onTouchStart={(e) => {
      //   e.preventDefault();
      //   console.log("touch2");
      //   // console.log("pointer", e.touches.length);
      // }}
    >
      <div id="div-canvas" ref={refDivCanvas}>
        <div className="iphone-personaliza arial">
          <p className="titulo">Nuevo Iphone XX</p>
        </div>

        <Canvas
          onCreated={({ gl }) => {
            gl.toneMapping = THREE.ACESFilmicToneMapping;
          }}
        >
          <Environment files="/studio.hdr" />

          <ambientLight intensity={1} />

          <OrbitControls
            rotation={[Math.PI * 1, 0, 0]}
            enablePan={false}
            maxDistance={2}
            minDistance={1}
          />
          <IphoneFinal color={color} />
        </Canvas>
        <div className="iphone-personaliza2 arial">
          <p className="text-personaliza  m-0">Elegí el color</p>
        </div>
        <div id="div-colors" ref={refColors}>
          <div id="color-1" onClick={() => setColor("cream")}></div>
          <div id="color-2" onClick={() => setColor("purple")}></div>
        </div>
      </div>

      <div className="fondo1">
        <p className="text-center" style={{ fontSize: "15px" }}>
          Verlo en Realidad Aumentada desde tu:
        </p>
        <div className="d-flex justify-content-between">
          <div></div>
          <div>
            <img
              src={recurso9}
              alt=""
              className="img-fluid mx-auto d-block "
              style={{
                alignSelf: "center",
              }}
            />
          </div>
          <a
            target="_blank"
            href="https://www.instagram.com/ar/854108392608386/?ch=OGE3MTg2MTM4NDA1MTUwYzIwNTYxMTM3YWZlMDRmMDM%3D"
            rel="noreferrer"
          >
            <img
              src={recurso8}
              alt=""
              className="img-fluid mx-auto d-block "
              style={{
                alignSelf: "center",
              }}
            />
          </a>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default IphoneSection;
