/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 iphone_final.gltf --transform
*/

import React, { useEffect, useRef, useState } from 'react'
import { Html, useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber';
export function IphoneFinal(props) {
  const [Color, setColor] = useState();
  const refColor1Original = useRef();
  const refColor2Original = useRef();
  const refColor3Original = useRef();
  const refColor4Original = useRef();
  const refColor5Original = useRef();
  const refCelu = useRef();


  const { nodes, materials } = useGLTF('/models/iphone5/iphone_final-transformed.glb')
  const color1 = "#E0D7C6";
  useEffect(() => {
    refColor1Original.current = materials.VJHCyyKuHhKvlMm.color
    refColor2Original.current = materials.ZYNtwrMLWZUrJLi.color
    refColor3Original.current = materials.GKNbhOkaDakjCoo.color
    refColor4Original.current = materials.WhfiHODTbOEWNGV.color
    refColor5Original.current = materials.pfPEqfMCyloOKjo.color
  }, []);

  useEffect(() => {
    
    if (props.color === "cream"){
        materials.VJHCyyKuHhKvlMm.color  = new THREE.Color(color1);
  materials.ZYNtwrMLWZUrJLi.color = new THREE.Color(color1);
  materials.GKNbhOkaDakjCoo.color = new THREE.Color(color1);
  materials.WhfiHODTbOEWNGV.color = new THREE.Color(color1);
  materials.pfPEqfMCyloOKjo.color = new THREE.Color(color1);
    }else if (props.color === "purple"){
      materials.VJHCyyKuHhKvlMm.color  = refColor1Original.current;
      materials.ZYNtwrMLWZUrJLi.color = refColor2Original.current;
      materials.GKNbhOkaDakjCoo.color = refColor3Original.current;
      materials.WhfiHODTbOEWNGV.color = refColor4Original.current;
      materials.pfPEqfMCyloOKjo.color = refColor5Original.current;
    }
  }, [props.color]);
  // materials.VJHCyyKuHhKvlMm.color  = new THREE.Color(color1);
  // materials.ZYNtwrMLWZUrJLi.color = new THREE.Color(color1);
  // materials.GKNbhOkaDakjCoo.color = new THREE.Color(color1);
  // materials.WhfiHODTbOEWNGV.color = new THREE.Color(color1);
  /**
   * 
   * Estos son otros colores
   */
  // materials.qlHIhxxFCgfqoHD.color = new THREE.Color(color1);
  // materials.qlHIhxxFCgfqoHD.color = new THREE.Color(color1);
  // materials.dgdEUEDEQYbCrTf.color = new THREE.Color(color1);
  // materials.jNyECXEBrYfagVt.color = new THREE.Color(color1);
  // materials.EyClxUfITiHwGZT.color = new THREE.Color(color1);
  // materials.jqKaFMJhdUMaWUx.color = new THREE.Color(color1);
  // materials.ACiMuCwNoeUYeuc.color = new THREE.Color(color1);
  // materials.sFfvWMfNbjocIhr.color = new THREE.Color(color1);
  
  useFrame(() => {
    if (!refCelu.current) return;
    refCelu.current.rotation.y += 0.001;
    // refCelu.current.rotation.y += 0.1;
  })
  return (
    <group {...props} dispose={null}  ref={(ref)  => {
      // props.ref2 = ref;
      refCelu.current = ref;
    }}>
      <group 
      // scale={0.1} 
      scale={0.12} 
      // position={[0.2,-0.73,0]} 
      position={[0.24,-0.7,0]} 
      rotation={[0,Math.PI * 1, 0]}
   
      >
        <mesh geometry={nodes.SdMBSwiAuAwKDLK.geometry} material={materials.qlHIhxxFCgfqoHD} />
        <mesh geometry={nodes.EljGlPJhiRkyxYF.geometry} material={materials.qlHIhxxFCgfqoHD} />
        <mesh geometry={nodes.JOPgTGrLeLxDnhr.geometry} material={materials.dgdEUEDEQYbCrTf} />
        <mesh geometry={nodes.lcJIkiwdSeaDNtW.geometry} material={materials.jNyECXEBrYfagVt} />
        <mesh geometry={nodes.pdHctPGnSVqoPOb.geometry} material={materials.EyClxUfITiHwGZT} />
        <mesh geometry={nodes.qyTQidmdCCELnDN.geometry} material={materials.jqKaFMJhdUMaWUx} />
        <mesh geometry={nodes.rbbmabnelxDunXl.geometry} material={materials.ACiMuCwNoeUYeuc} />
        <mesh geometry={nodes.CPWDnHBKjqwmWPw.geometry} material={materials.qlHIhxxFCgfqoHD} />


        <mesh geometry={nodes.hAEgFJWoOIWESWO.geometry} material={materials.GKNbhOkaDakjCoo} />
        <mesh geometry={nodes.VYAQQJUayECLLZf.geometry} material={materials.sFfvWMfNbjocIhr} />
        
        <mesh geometry={nodes.kiIGxAxqpVSYFBw.geometry} material={materials.ZYNtwrMLWZUrJLi} />
        <mesh geometry={nodes.RrRDmwjkYAIxgBc.geometry} material={materials.GKNbhOkaDakjCoo} />
        <mesh geometry={nodes.tKAIzNzCEjxzprt.geometry} material={materials.WhfiHODTbOEWNGV} />


        <mesh geometry={nodes.UxpqqtHZacqDuAD.geometry} material={materials.qlHIhxxFCgfqoHD} />
        <mesh geometry={nodes.yxHDPuRCYxMKcmh.geometry} material={materials.sFfvWMfNbjocIhr} />
        <mesh geometry={nodes.CapaHBiboQfcpks.geometry} material={materials.jrdrKWhcMnqovcq} />
        <mesh geometry={nodes.EGfNmHiKmiNARPq.geometry} material={materials.BaGOYZvLiVSnisX} />
     
        <mesh geometry={nodes.qppjGZAXYYiousn_0.geometry} material={materials.DlreGdJPTmluSwD} />
        <mesh geometry={nodes.SgjyVFLdULTDDyP_0.geometry} material={materials.IKMyTXZPdUvHaky} />
        <mesh geometry={nodes.yFGgZCUkYbSAoFm_0.geometry} material={materials.dfaubriZrrIPwGp} />
        <mesh geometry={nodes.YpxUxkzAUlGihpo_0.geometry} material={materials.jqKaFMJhdUMaWUx} />
        <mesh geometry={nodes.EiSlFTgIvOYjhVP_0.geometry} material={materials.VamXAtCQwCWikPP} />

        {/* Aca esta la pantalla */}

          {/* <Html className="content" 
        style={{
          transform: `scale( 0.8 )`
        }}
        material={
          <meshStandardMaterial
            side={THREE.DoubleSide} 
            opacity={0.8} 
          />
        }
      
        rotation-y={Math.PI}
        occlude
         position={[1.99, 7, -0.09]} 
         
         transform >
              <div className="wrapper" onPointerDown={(e) => e.stopPropagation()}>
               <iframe src="https://clarin.com"  title="Iframe Example"
               style={{width:'320px',
              height:'100%',
              borderRadius:'10px'
            }}
               ></iframe>
              </div>
            </Html> */}
    
        <mesh geometry={nodes.OnWRLMuCmrmdFOB_0.geometry} material={materials.WUEeHdZRDsvAAGs}>
        </mesh>


        <mesh geometry={nodes.WNYCPJkCFcTQSHD.geometry} material={materials.kmebBCaXAjeNgHm} />
        <mesh geometry={nodes.xoELmpwJPmWyqpz.geometry} material={materials.buDcsbyHyZhVHWL} />
        <mesh geometry={nodes.ajSRYmZcySeLEJD.geometry} material={materials.nnJkGPkaCqeYbJN} />
        <mesh geometry={nodes.FIrTiaKLJUvmYaQ.geometry} material={materials.NYrpmOZEHAizmgt} />

        <mesh geometry={nodes.srCbMdpcckYfXhy.geometry} material={materials.qlHIhxxFCgfqoHD} />
        <mesh geometry={nodes.YBZExenqdltdSVv.geometry} material={materials.UahAVEOjUGkWynW} />
           {/* aca abajo */}
{/* La siguiente linea es la tapa de atras */}
        <mesh geometry={nodes.jybtuMvZaGSbJkI_0.geometry} material={materials.VJHCyyKuHhKvlMm} />
        <mesh geometry={nodes.OMJLoOGbzzXVxDp.geometry} material={materials.SrWQZkpuIHDvayJ} />


        <mesh geometry={nodes.pgJsBHaVWUxfsIj.geometry} material={materials.kwAXAaDdpeiqZjx} />
        {/* Esta es la tapa de la camara */}
        <mesh geometry={nodes.rfuWtGNHGojovDQ_0.geometry} material={materials.pfPEqfMCyloOKjo} />


        <mesh geometry={nodes.SpAMRkQqBaKLPiV.geometry} material={materials.ZZhIxjEiOimshEQ} />

        <mesh geometry={nodes.DPIzJkVXUfWdEUc_0.geometry} material={materials.VJHCyyKuHhKvlMm} />
        <mesh geometry={nodes.pXtrcbcvaofHzOj_0.geometry} material={materials.SspdWDmNzqHMukR} />
        <mesh geometry={nodes.VXiDSxNyxZuoBDl.geometry} material={materials.qlHIhxxFCgfqoHD} />
        <mesh geometry={nodes.QHMrToZBMmdEMeT_0.geometry} material={materials.jTDAiLdumdHwZMC} />


        <mesh geometry={nodes.suftmnxxGixjMtr_0.geometry} material={materials.xXBNhDfcAFZjUxC} />
        <mesh geometry={nodes.tgUzMnXQNdYMviI_0.geometry} material={materials.NLOUuAVTMPJZrDt} />
        <mesh geometry={nodes.weZOVmeUeByNxah.geometry} material={materials.ZtQKPTaehYFyWkK} />
        <mesh geometry={nodes.xUAjEeOyCmHdAQI.geometry} material={materials.SspdWDmNzqHMukR} />
        <mesh geometry={nodes.AHNNItSgANDvOSq.geometry} material={materials.plOOeNAjbeqKVbt} />

        <mesh geometry={nodes.DcLVOGRoBYVkNZb.geometry} material={materials.HKtUQFiJxxaCOGX} />
        <mesh geometry={nodes.ebGMZBIBbRlRYGw.geometry} material={materials.InTnJqdXKXuhYXe} />
        <mesh geometry={nodes.fNpWQyPEuJqAZtG.geometry} material={materials.NYrpmOZEHAizmgt} />
        <mesh geometry={nodes.gTLOJAxDWGAJdqo.geometry} material={materials.kmebBCaXAjeNgHm} />
        <mesh geometry={nodes.JPkQervZJlyuytT.geometry} material={materials.qlHIhxxFCgfqoHD} />
        <mesh geometry={nodes.MaRCHObTxlTNTsZ.geometry} material={materials.SspdWDmNzqHMukR} />
        <mesh geometry={nodes.OIBroXzCMNGWTNf.geometry} material={materials.UahAVEOjUGkWynW} />
        <mesh geometry={nodes.rPYmjHeAsIrXjBX.geometry} material={materials.ZYNtwrMLWZUrJLi} />
        <mesh geometry={nodes.UIMdHupsMbHukgX.geometry} material={materials.ZYNtwrMLWZUrJLi} />
        <mesh geometry={nodes.XeVFqjewgRURJUe.geometry} material={materials.SspdWDmNzqHMukR} />
        <mesh geometry={nodes.xfOGLIMPGtYiQwD.geometry} material={materials.qlHIhxxFCgfqoHD} />
      </group>
    </group>
  )
}

useGLTF.preload('/models/iphone5/iphone_final-transformed.glb')
